<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <b-img
          :src="appLogoImage"
          alt="logo"
          style="width: 200px;"
        />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="7"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Register V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
        lg="5"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title class="mb-1">
            Verifikasi Email
          </b-card-title>
          <b-card-text class="mb-0 text-justify">
            Link verifikasi telah berhasil dikirimkan ke email <strong>{{ $route.query.mailto }}</strong>
          </b-card-text>
          <b-card-text class="mb-2 text-justify">
            Silakan cek kotak masuk atau folder spam pada email kamu dan klik link untuk melakukan verifikasi, atau dapat mengirim ulang verifikasi email dengan menekan tombol dibawah ini.
          </b-card-text>
          
          <p class="text-center mt-2">
                <vue-countdown-timer
                  :start-time="'2021-06-04 10:00:00'"
                  :end-time="end_time"
                  :interval="1000"
                  :day-txt="null"
                  :hour-txt="null"
                  :seconds-txt="''"
                  label-position="begin">
                  <template slot="start-label">
                    Kirim ulang Email Verifikasi setelah
                  </template>
                  <template slot="countdown" slot-scope="scope">
                    <span>{{scope.props.minutes}}</span>{{scope.props.minutesTxt}}<span>{{scope.props.seconds}}</span>{{scope.props.secondsTxt}}
                  </template>
                  <template slot="end-text">
                    <!-- <VueLoadingButton
                      class="btn btn-primary btn-block"
                      type="submit"
                      @click.native="resendVerification"
                      :loading="isLoading"
                      :styled="true"
                      style="font-size: 15px"
                    >Kirim Ulang Verifikasi Email</VueLoadingButton> -->
                    <ButtonLoading
                      buttonText="Kirim Ulang Verifikasi Email"
                      :isLoading="isLoading"
                      @action="resendVerification"
                    />
                  </template>
                </vue-countdown-timer>
          </p>
        </b-col>
      </b-col>
    <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BButton, BForm, BFormCheckbox, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BImg, BCardTitle, BCardText,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store/index'
import axios from "@axios"
import ButtonLoading from '@/views/components/button/ButtonLoading.vue'
import { $themeConfig } from '@themeConfig'
// App Name
const { appName, appLogoImage } = $themeConfig.app

export default {
  components: {
    VuexyLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
    ButtonLoading
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      phoneNumber: '',
      userEmail: '',
      password: '',
      sideImg: require('@/assets/images/pages/register-v2.svg'),
      // validation
      required,
      email,
      end_time: null,
      isLoading: false,
      appName,
      appLogoImage
    }
  },
  mounted() {
    let _ = this
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    async resendVerification() {
        let _ = this
        _.isLoading = true
        
        axios.post('account/resend-activation', {email: _.$route.query.mailto})
        .then(resp => {
            let msg = resp.data.message
            _.$toast({
                component: ToastificationContent,
                props: {
                    title: msg,
                    icon: 'EditIcon',
                    variant: 'success',
                },
            })
            _.isLoading = false
        })
        .then(() => _.restartCounter())
        .catch(err => {
            console.log(err)
            _.isLoading = false
        })
    },
    restartCounter() {
      let _ = this
      var now = new Date();
      now.setSeconds(now.getSeconds() + 61); // timestamp
      now = new Date(now).getTime(); // Date object
      
      _.end_time = now
    }
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.spinner {
  -webkit-animation: none
}
</style>
